import React from "react";
import { Space, Tooltip, Typography } from "antd";
import { ClockCircleTwoTone } from "@ant-design/icons";
import moment from "moment";
const { Text } = Typography;
/**
 * TimeStatusComponent displays a formatted time and optionally an icon
 * if the time is after the delivery window.
 *
 * @param {Object} props - The component props
 * @param {string} props.time - The time to display and compare, in any format Moment.js can parse
 * @param {Object} props.deliveryWindow - The delivery window object
 * @param {string} props.deliveryWindow.earliestArrivalDateTime - The earliest arrival time
 * @param {string} props.deliveryWindow.latestArrivalDateTime - The latest arrival time
 * @returns {React.ReactElement|null} The rendered component or null if time is not provided
 */
const TimeStatusComponent = ({ time, deliveryWindow }) => {
  if (!time) {
    return null; // Return empty cell if time is null or empty
  }

  const displayTime = moment(time).format("hh:mm A");

  const isAfterDeliveryWindow = () => {
    if (!deliveryWindow || !deliveryWindow.latestArrivalDateTime) {
      return false; // Don't show clock icon if there's no delivery window
    }

    const formattedTime = moment(time);
    const formattedLatestArrivalTime = moment(
      deliveryWindow.latestArrivalDateTime
    );

    return (
      formattedTime.format("HH:mm") > formattedLatestArrivalTime.format("HH:mm")
    );
  };

  const tooltipContent =
    deliveryWindow && deliveryWindow.latestArrivalDateTime
      ? `${moment(deliveryWindow.earliestArrivalDateTime).format(
          "hh:mm A"
        )} - ${moment(deliveryWindow.latestArrivalDateTime).format("hh:mm A")}`
      : "";

  return (
    <Space size="small">
      <Text style={{ whiteSpace: "nowrap" }}>{displayTime}</Text>
      {isAfterDeliveryWindow() && (
        <Tooltip title={tooltipContent}>
          <ClockCircleTwoTone twoToneColor="#F96302" />
        </Tooltip>
      )}
    </Space>
  );
};

export default TimeStatusComponent;
