import Axios from "axios";
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  CHANGE_ROLE,
} from "../constants/userDetailsConstants";
import getBffUrl from "../../util/getBffUrl";
import {
  USER_ROLES_SESSION_STORAGE_KEY,
  USER_VENDOR_NUMBER_SESSION_STORAGE_KEY,
} from "../../util/Constants";

export const getUserDetails = (dispatch) => async (ldap) => {
  dispatch({
    type: USER_DETAILS_REQUEST,
  });
  try {
    const { data } = await Axios.get(`${getBffUrl()}/user/v1/${ldap}`);
    if (data.companyName && data.companyName.startsWith("GE")) {
      const savedRoles = sessionStorage.getItem(USER_ROLES_SESSION_STORAGE_KEY);
      const savedVendorNumber = sessionStorage.getItem(
        USER_VENDOR_NUMBER_SESSION_STORAGE_KEY
      );
      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: {
          ...data,
          roles: savedRoles ? savedRoles.split(",") : null,
          isGEUser: true,
          mvendorNum: savedVendorNumber,
        },
      });
    } else {
      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: {
          ...data,
          mvendorNum: data.mvendorList
            ? data.mvendorList.length
              ? data.mvendorList[0].vendorNumber
              : null
            : null,
        },
      });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const changeRole = (dispatch) => (roles) => {
  dispatch({
    type: CHANGE_ROLE,
    payload: roles,
  });
};
