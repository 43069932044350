export const PO_RECEIVED = "PO RECEIVED";
export const COMPLETE = "COMPLETE";
export const NA = "Na";
export const NULL = "Null";
export const ALLDAY = "ALLDAY";
export const PAST = "past";
export const FUTURE = "future";
export const CURRENT = "current";
export const DELIVERYNOTETYPE = "2";
export const DEPARTMENT_NUMBER = 29;
export const QUANTITY = 1;
export const SPACE = " ";
export const DELIVERYNOTEHEADER = "MDO - ";
export const ORDER = "ORDER";

//Work Order Status
export const WORK_ORDER_CREATED = "Work Order Created";
export const SUBMITTED_AND_APPROVED = "Submitted And Approved";
export const READY_FOR_LOAD = "Ready For Load";
export const IN_TRANSIT = "In-Transit";
export const DISPATCH_REJECTED = "Dispatch Rejected";
export const DELIVERY_CONFIRMATION_OBTAINED = "Delivery Confirmation Obtained";
export const DELIVERY_COMPLETED = "Delivery Completed";
export const DELIVERY_ATTEMPTED = "Delivery Attempted";
export const WORK_ORDER_CANCELLED = "Work Order Cancelled";

// New Manual Order
export const REVISIT_MODEL_NUMBER = "Revisit";
export const REVISIT_SKU = "863701";

// Event Descriptions
export const ORDER_PROCESSED = "Order processed";
export const PO_RECEIVED_EVENT = "PO received";
export const ON_THE_WAY = "On the way";
export const OUT_FOR_DELIVERY = "Out for delivery";
export const AT_LOCATION = "At Location";
export const DELIVERED = "Delivered";
export const DELIVERED_W_EXCEPTION = "Delivered with exception";
export const MISSED_W_ATTEMPT = "Missed with attempt";
export const MISSED_WO_ATTEMPT = "Missed without attempt";
export const CANCELLED = "Cancelled";
export const RESCHEDULED = "Rescheduled";

// Month Names
export const JANUARY = "January";
export const FEBRUARY = "February";
export const MARCH = "March";
export const APRIL = "April";
export const MAY = "May";
export const JUNE = "June";
export const JULY = "July";
export const AUGUST = "August";
export const SEPTEMBER = "September";
export const OCTOBER = "October";
export const NOVEMBER = "November";
export const DECEMBER = "December";

// User Roles
export const ROLE_EMT_CORP = "EMT_Corp";
export const ROLE_EMT_N = "EMT_N";
export const ROLE_ADMS_MDO_USERS = "ADMS_MDO_USERS";
export const ROLE_ADMS_LOGISTICS_MANAGERS = "ADMS Logistics Managers";
export const ROLE_ADMS_LOGISTICS_USERS = "ADMS Logistics Users";
export const ROLE_ADMS_DELIVERY_AGENTS = "ADMS Delivery Agents";
export const ROLE_ADMS_VENDORS = "ADMS Vendors";
export const ROLE_DMP_CALL_CENTER = "DMP Call Center";
export const DMP_WORKBIN_ACCESS_GROUP = "DMP Workbin";
export const DMP_DASHBOARD_COMMON_ACCESS_GROUP = "DMP_Dashboard_Common";
export const DMP_CANCEL_DELIVERY_ACCESS_GROUP = "MDO_Appliance_Delivery_Cancel";
export const DMP_DASHBOARD_STH_ACCESS_GROUP = "DMP_Dashboard_STH";

//Revisit Reasons
export const REVISIT_REPLACEMENT = "Replacement";
export const REVISIT_PICKUP = "Pick-up for Return";
export const REVISIT_DA_SERVICE_CALL = "Delivery Agent Service Call";
export const REVISIT_PLUMBER_ELECTRICIAN_SERVICE_CALL =
  "Plumber/Electrician Service Call";
export const REVISIT_OTHER = "Other";

export const REVISITS_WITH_DELIVERY_QUESTION = [
  REVISIT_DA_SERVICE_CALL,
  REVISIT_PLUMBER_ELECTRICIAN_SERVICE_CALL,
  REVISIT_OTHER,
];

// Mail Specific Message
export const MESSAGE_CLOSED_SUCCESS = "Success";
export const DEFAULT_MESSAGE_CLOSED_SUCCESS =
  "This mail message has been closed";
export const DEFAULT_MESSAGE_CLOSED =
  "Do you want to move this message to the History folder?";

//Delivery Notes Status

export const DELIVERY_STATUS = {
  success: "Success",
  fail: "Fail",
  na: "Na",
};

export const NOTES_LABELS = {
  order: "Order",
  product: "Product",
  delivery: "Delivery",
};

export const DELIVERY_LINE_NUMBER = 9999;

export const GE_SUBROLES = {
  "ADMS Vendors": "Mainland OEM",
  "PR ADMS Vendors": "Puerto Rico OEM",
};

export const USER_ROLES_SESSION_STORAGE_KEY = "roles";
export const USER_VENDOR_NUMBER_SESSION_STORAGE_KEY = "mvendorNum";

export const SUCCESS_NOTIFICATION_CONFIGS = {
  duration: 0,
  top: 120,
  style: {
    border: "1px solid #00AE4D",
    borderLeft: "7px solid #00AE4D",
    background: "#ECFFF5",
  },
};

export const ERROR_NOTIFICATION_CONFIGS = {
  duration: 0,
  top: 120,
  style: {
    border: "1px solid #ED1C24",
    borderLeft: "7px solid #ED1C24",
    background: "#FEF2F2",
  },
};

export const RESCHEDULE_REASON_CODES = {
  1: "Backorder",
  2: "Late Truck",
  3: "Weather",
  4: "Missing Product",
  5: "Customer Not at Home",
  6: "Damage",
  7: "Customer Reschedule",
  8: "Site Readiness",
  9: "Wrong Product",
  10: "Other (Note required)",
  11: "Delivery Vendor Processed",
};

export const GE_PR_VENDOR_NUMBER = "60030263";

export const DASHBOARD_CONTEXT = "Dashboard";
export const DASHBOARD_COMMON_CONTEXT = "Dashboard Common";
export const DASHBOARD_STH_CONTEXT = "Dashboard STH";
export const DASHBOARD_ROUTE_PLANNER_CONTEXT = "route-planner";
//Line Item Types
export const APPLIANCE_LINE = "APPLIANCE";
export const PARTS_SERVICES_LINE = "PARTS";
export const DEFAULT_REVISIT_LINE = "DEFAULT";
export const PROTECTION_PLAN_LINE = "EPP";

export const REVISIT_RETURN_SERVICE_CODE = "10018";
export const REVISIT_REPLACEMENT_SERVICE_CODE = "10022";
export const REVISIT_DA_SERVICE_CALL_SERVICE_CODE = "10023";
export const REVISIT_OTHER_SERVICE_CODE = "10024";
export const REVISIT_PE_SERVICE_CALL_SERVICE_CODE = "10025";

export const REVISIT_SERVICE_CODES_MAP = new Map([
  [REVISIT_RETURN_SERVICE_CODE, "Pick-up for Return"],
  [REVISIT_REPLACEMENT_SERVICE_CODE, "Replacement"],
  [REVISIT_DA_SERVICE_CALL_SERVICE_CODE, "Delivery Agent Service Call"],
  [REVISIT_OTHER_SERVICE_CODE, "Other"],
  [REVISIT_PE_SERVICE_CALL_SERVICE_CODE, "Plumber/Electrician Service Call"],
]);

export const SOURCE_SYSTEM_ADMS = "ADMS";
export const SOURCE_SYSTEM_ORDER_UP = "ORDERUP";
export const SOURCE_SYSTEM_ONLINE = "ONLINE";
export const SOURCE_SYSTEM_AOS = "AOS";

export const STH_DELIVERY_STATUS_MESSAGE_MAP = {
  COMP_W_EXP: "Delivered",
  COMPLETED: "Delivered",
  MSD_W_ATT: "Delivery Attempted",
  MSD_WO_ATT: "Delivery Attempted",
  ARR_DLVER: "In transit on",
  ARR_PICKUP: "In transit on",
  DEP_DLVER: "In transit on",
  DEP_PICKUP: "In transit on",
  EN_ROUTE: "In transit on",
  PENDING: "Scheduled for",
  NA: "Scheduled for",
  CANCELED: "Order Cancelled",
};
