import React from "react";
import { Drawer, Divider, Spin } from "antd";
import { useStopFieldData } from "../../hooks/RoutePlanner/useStopFieldData";
import useNewRelicMetrics from "../../hooks/newRelicMetricsHook";
import { RP_VIEW_EVENTS } from "../../util/NewRelicConstants";

export default function DriverNotesDrawer({
  isDrawerOpen,
  setDrawerOpen,
  selectedStopId,
  driverNotesWorkOrderNumber,
  driverNotesOrderNumber,
}) {
  const { stopFieldData, loading, error, contextHolder } =
    useStopFieldData(selectedStopId);

  const { logNewRelicMetricsEvent } = useNewRelicMetrics();

  return (
    <div>
      {error && (
        <div>
          <span className={""}></span>
        </div>
      )}
      {contextHolder}
      <Drawer
        title="Driver Notes"
        onClose={() => setDrawerOpen(false)}
        open={isDrawerOpen}
        afterOpenChange={() => {
          logNewRelicMetricsEvent(RP_VIEW_EVENTS.DRIVER_NOTES.OPEN);
        }}
      >
        <div className="driver-notes-header">
          <p>
            Order Number:{" "}
            {driverNotesOrderNumber ? driverNotesOrderNumber : "None Available"}
          </p>
          <p>
            Work Order Number:{" "}
            {driverNotesWorkOrderNumber
              ? driverNotesWorkOrderNumber
              : "None Available"}{" "}
          </p>
        </div>
        <Divider />

        {loading ? (
          <div className="rp-details-spinner" data-testid="rp-details-spinner">
            <Spin size="large" />
          </div>
        ) : stopFieldData &&
          stopFieldData.fieldData &&
          stopFieldData.fieldData.length > 0 ? (
          stopFieldData.fieldData.map((fieldDataEntry, fieldDataEntryIndex) => (
            <div className="driver-note-record" key={fieldDataEntryIndex}>
              {fieldDataEntry.commentName && fieldDataEntry.commentValue && (
                <div className="driver-note-text">
                  <b>{fieldDataEntry.commentName}</b>
                  <p>{fieldDataEntry.commentValue}</p>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="driver-note-empty">
            <b>No field data to show</b>
          </div>
        )}
      </Drawer>
    </div>
  );
}
