import React, { useContext } from "react";
import "./Search.less";
import { Button, Col, Input, Row, Select } from "antd";
import { ReactComponent as RefreshIcon } from "./Images/refresh.svg";
import DateFilter from "./Filter/DateFilter/DateFilter";
import DashboardContext from "../../context/DashboardContext";
import NewManualOrderButton from "../NewManualOrder/NewManualOrderButton";
import { useLocation } from "react-router-dom";
import * as constants from "../../util/Constants";
import useNewRelicMetrics from "../../hooks/newRelicMetricsHook";
import { SEARCH_CLEAR_EVENT, SEARCH_EVENT } from "../../util/NewRelicConstants";
import { useSearchStateHook } from "../../hooks/useSearchStateHook";
import SearchContext from "../../context/SearchContext";
import {
  SEARCH_TYPES,
  sanitizer,
  inputValidation,
  sanitizeInput,
  maskInput,
  isRetry,
} from "./search.helper";

const Search = () => {
  const { persist, update, persistedValues, values, reset, resetFilters } =
    useSearchStateHook(
      {
        type: "",
        input: "",
        optionalInput: "",
        date: "",
      },
      { sanitizer }
    );
  const dashboardContext = useContext(DashboardContext);
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();
  const location = useLocation();
  const path = location.pathname;
  const isValidInput = inputValidation(values);
  const { loading } = useContext(SearchContext);

  function getKeyword() {
    return values.optionalInput
      ? `(${values.input}, ${values.optionalInput})`
      : values.input;
  }

  function handleCalendarChange(values) {
    resetFilters();
    update(values, { persist: true });
    window.location.reload();
  }

  function handleSearch() {
    const keyword = getKeyword();
    logNewRelicMetricsEvent(SEARCH_EVENT, {
      keyword,
      searchGroup: values.type,
    });

    resetFilters();

    const additionalValues = {};
    const opts = { replace: false };
    const shouldRetry = isRetry(persistedValues, values);
    if (shouldRetry) {
      const retryString = parseInt(persistedValues.retry, 10);
      const retry = isNaN(retryString) ? 1 : retryString + 1;
      additionalValues.retry = retry;
      opts.replace = true;
    } else {
      additionalValues.retry = undefined;
    }
    persist(opts, additionalValues, {
      resetExcept: ["input", "type", "optionalInput", "retry"],
    });
    window.location.reload();
  }

  function handleClear() {
    logNewRelicMetricsEvent(SEARCH_CLEAR_EVENT, {});
    reset();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (isValidInput) {
      handleSearch();
    }
  };

  const searchInputComponent = () => {
    if (values.type === "CustomerName" || values.type === "PONumber") {
      let firstPlaceholderText = "";
      let secondPlaceholderText = "";
      if (values.type === "CustomerName") {
        firstPlaceholderText = "First (optional)";
        secondPlaceholderText = "Last";
      } else if (values.type === "PONumber") {
        firstPlaceholderText = "Store# (optional)";
        secondPlaceholderText = "PO#";
      }
      return (
        <form className="search-form" onSubmit={handleSubmit}>
          <Input
            className="first-input"
            size="large"
            onChange={(event) => {
              update({ optionalInput: event.target.value });
            }}
            value={values.optionalInput}
            placeholder={firstPlaceholderText}
          />
          <Input
            className="last-input"
            id="search-input"
            size="large"
            onChange={(event) => {
              update({ input: event.target.value });
            }}
            value={values.input}
            placeholder={secondPlaceholderText}
          />
          <Button
            className="search-btn"
            disabled={!isValidInput || loading}
            size="large"
            type="primary"
            htmlType="submit"
          >
            Search
          </Button>
        </form>
      );
    } else {
      let placeholderText = "";
      if (values.type === "MSNumber") {
        placeholderText = "ex 1AHV18L8NX";
      } else if (values.type === "OrderNumber") {
        placeholderText = "ex H0121-127156, W123456789, C123456789";
      } else if (values.type === "WONumber") {
        placeholderText = "ex W123456789-12345678";
      } else if (values.type === "CustomerPhone") {
        placeholderText = "";
      }
      return (
        <form onSubmit={handleSubmit} className="search-form">
          <Input
            style={{ flex: 1 }}
            id="search-input"
            className="search-input"
            placeholder={placeholderText}
            size="large"
            onChange={(event) => {
              const value = sanitizeInput(event.target.value, values.type);
              update({ input: value });
            }}
            value={maskInput(
              sanitizeInput(values.input, values.type),
              values.type
            )}
          />
          <Button
            className="search-btn"
            disabled={!isValidInput || loading}
            size="large"
            type="primary"
            htmlType="submit"
          >
            Search
          </Button>
        </form>
      );
    }
  };

  function getAvailableSearchTypes() {
    const searchTypes = SEARCH_TYPES.slice();
    if (dashboardContext === constants.DASHBOARD_STH_CONTEXT) {
      return searchTypes.slice(1, 2).concat(searchTypes.slice(5));
    } else {
      return searchTypes.slice(0, 5);
    }
  }

  return (
    <div id="search-container">
      <Row>
        <Col
          xl={{ span: 12 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <div className="search-group">
            <label htmlFor="Search">Find Orders</label>
            <Input.Group compact>
              <Select
                size="large"
                popupClassName="search-dropdown"
                onChange={(value) => {
                  const opts = {
                    reset: true,
                  };
                  if (!values.type) {
                    opts.reset = false;
                  }
                  update({ type: value }, opts);
                }}
                value={
                  values.type === "" || values.type === "DeliveryDate"
                    ? "Search by..."
                    : values.type
                }
                options={getAvailableSearchTypes()}
              ></Select>
              {searchInputComponent()}
            </Input.Group>
          </div>
        </Col>
        {values.type && values.input && values.type !== "DeliveryDate" ? (
          <Col
            xl={{ span: 6 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <div id="clear">
              <Button
                id="clear-search-button"
                type="link"
                onClick={handleClear}
              >
                <RefreshIcon />
                <span id="clear-label">Clear search</span>
              </Button>
            </div>
          </Col>
        ) : (
          <Col
            xl={{ span: 6 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <DateFilter
              onChange={handleCalendarChange}
              hideDateSelection={
                dashboardContext === constants.DASHBOARD_STH_CONTEXT
              }
            />
          </Col>
        )}
        {path === "/" ? (
          <Col
            xl={{ span: 6 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <NewManualOrderButton />
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default Search;
